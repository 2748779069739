import Firebase from 'firebase/compat/app'
import ReactGA from 'react-ga'
import { prepareDocForUpdate } from './helpers/firestoreHelpers'

const updateImport = (importId, values) => {

  ReactGA.event({
    category: 'Import',
    action: 'Update import',
  })

  values.lastUpdatedTimestamp = (new Date()).getTime();
  values.ownedByName = Firebase.auth().currentUser.displayName

  return Firebase.firestore()
    .collection('imports')
    .doc(importId)
    .update(prepareDocForUpdate(values))
    .catch(error => {
      alert(`Error! Couldn't edit the import: ${error.message}`)
    })
}

export default updateImport
