import React from 'react'
import { FirestoreCollection, FirestoreDocument } from 'react-firestore'

import Error from '../misc/Error'
import { Link } from 'react-router-dom'
import { format } from 'timeago.js';
import { saveAs } from 'file-saver';
import {
  Button,
  Input,
  Icon,
  Table,
  Menu,
  Form,
  Divider
} from 'semantic-ui-react'

class ImportList extends React.Component {
  displayData = [];

  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
      currentPage: 0,
      searchFilter: null,
      sortByFilter: "fileNo:desc",
      templatePageSize: 10,
      templateCurrentPage: 0,
      templateSearchFilter: null
    };
  }

  lastPage = () => {
    this.setState({ currentPage: this.state.currentPage - 1 })
  }

  nextPage = () => {
    this.setState({ currentPage: this.state.currentPage + 1 })
  }

  gotoPage = (v) => {
    this.setState({ currentPage: v })
  }

  lastPageTemplate = () => {
    this.setState({ templateCurrentPage: this.state.templateCurrentPage - 1 })
  }

  nextPageTemplate = () => {
    this.setState({ templateCurrentPage: this.state.templateCurrentPage + 1 })
  }

  gotoPageTemplate = (v) => {
    this.setState({ templateCurrentPage: v })
  }

  onSearch = event => {
    event.preventDefault()
    const { fileNo, mblNo, hblNo, amshblNo, importerName, consigneeName } = event.target.elements

    let search = []

    if (fileNo.value.length > 0)
      search.push(['fileNo', '>=', fileNo.value.toUpperCase()])

    if (mblNo.value.length > 0)
      search.push(['mblNo', '>=', mblNo.value.toUpperCase()])

    if (hblNo.value.length > 0)
      search.push(['hblNo', '>=', hblNo.value.toUpperCase()])

    if (amshblNo.value.length > 0)
      search.push(['amshblNo', '>=', amshblNo.value.toUpperCase()])

    if (importerName.value.length > 0)
      search.push(['importerName', '>=', importerName.value.toUpperCase()])

    if (consigneeName.value.length > 0)
      search.push(['consigneeName', '>=', consigneeName.value.toUpperCase()])

    this.setState({
      searchFilter: (search.length > 0) ? search : null,
      sortByFilter: "fileNo:desc"
    })
  }

  downloadSearchReport = () => {
    function ConvertToCSV(objArray) {
      var str = '';

      // Add headers
      var tableHeaders = ["File No.", "MBL No.", "HBL No.", "Consignee", "Importer", "ETD", "ETA", "Billing - Prepaid", "Billing - Collect"]
      str += tableHeaders.join() + '\r\n';

      objArray.forEach( importFile => {
        const invoice = JSON.parse(importFile.invoice);
        const prepaidAmt = invoice.reduce((value, billItem) => Number(billItem.prepaid) + value, 0);
        const collectAmt = invoice.reduce((value, billItem) => Number(billItem.collect) + value, 0);

        var rowValues = [
            importFile.fileNo,
            importFile.mblNo,
            importFile.hblNo,
            importFile.consigneeName,
            importFile.importer,
            importFile.etd,
            importFile.eta,
            prepaidAmt.toFixed(2),
            collectAmt.toFixed(2)
          ];
          var line = `"${rowValues.join("\",\"")}"`;
          str += line + '\r\n';
      })

      return str;
    }

    var content = ConvertToCSV(this.displayData);
    // any kind of extension (.txt,.cpp,.cs,.bat)
    var filename = "report.csv";

    var blob = new Blob([content], {
    type: "text/plain;charset=utf-8"
    });

    saveAs(blob, filename);
  }

  render() {
    return (
      <React.Fragment>
        <Button primary icon="add" content='New Import' as={Link} to="/imports/new"></Button>
        <p />
        <Form onSubmit={this.onSearch} >
          <Form.Group>
            <Form.Input type="text" width={3} placeholder="File No." name="fileNo" />
            <Form.Input type="text" width={3} placeholder="MBL No." name="mblNo" />
            <Form.Input type="text" width={3} placeholder="AMS HBL No." name="amshblNo" />
            <Form.Input type="text" width={3} placeholder="HBL No." name="hblNo" />
            <Form.Input type="text" width={3} placeholder="Importer" name="importerName" />
            <Form.Input type="text" width={3} placeholder="Consignee" name="consigneeName" />
            <Form.Button primary type="submit">Search</Form.Button>
          </Form.Group>
        </Form>

        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='10'>
                {!this.state.searchFilter ? "Imports on File" : "Search Results"}
                {this.state.searchFilter &&
                  <Button primary icon labelPosition='right' floated='right' size='mini' 
                    onClick={() => this.downloadSearchReport()} >
                    Download Report
                    < Icon name='download' />
                  </Button>
                }
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>File No.</Table.HeaderCell>
              <Table.HeaderCell>MBL No.</Table.HeaderCell>
              <Table.HeaderCell>HBL No.</Table.HeaderCell>
              <Table.HeaderCell>Importer</Table.HeaderCell>
              <Table.HeaderCell>Notify Party</Table.HeaderCell>
              <Table.HeaderCell>ETD</Table.HeaderCell>
              <Table.HeaderCell>ETA</Table.HeaderCell>
              <Table.HeaderCell>Last Updated</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <FirestoreCollection
            path={'imports'}
            sort={this.state.sortByFilter}
            limit={(this.state.searchFilter == null) ? this.state.currentPage * this.state.pageSize + this.state.pageSize : null}
          >
            {({ error, isLoading, data }) => {

              if (error) {
                return <Error error={error} />
              }

              if (isLoading) {
                return <Table.Body>
                  <Table.Row>
                    <Table.Cell>Loading, Please wait...</Table.Cell>
                  </Table.Row>
                </Table.Body>
              }

              if (data.length === 0) {
                return <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan="10">No imports found.
                      {(this.state.searchFilter) ? <Button floated='right' onClick={() => this.setState({ searchFilter: null })}>Clear Search</Button> : null}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              }

              let displayData = data

              if (this.state.searchFilter == null) {
                displayData = displayData.slice(this.state.currentPage * this.state.pageSize,
                  this.state.currentPage * this.state.pageSize + this.state.pageSize)
              } else {
                this.state.searchFilter.forEach( search => {
                    switch(search[0]) {
                      case 'fileNo': displayData = displayData.filter( importFile => 
                        (importFile.fileNo.includes(search[2]))
                      ); break;
                      case 'mblNo': displayData = displayData.filter( importFile => 
                        (importFile.mblNo.includes(search[2]))
                      ); break;
                      case 'hblNo': displayData = displayData.filter( importFile => 
                        (importFile.hblNo.includes(search[2]))
                      ); break;
                      case 'amshblNo': displayData = displayData.filter( importFile => 
                        (importFile.amshblNo.includes(search[2]))
                      ); break;
                      case 'importerName': displayData = displayData.filter( importFile => 
                        (importFile.importer.includes(search[2]))
                      ); break;
                      case 'consigneeName': displayData = displayData.filter( importFile => 
                        (importFile.consigneeName.includes(search[2]))
                      ); break;
                    }
                  }
                )
              }

              this.displayData = displayData

              return <Table.Body>
                {displayData.map(importFile => (
                    <Table.Row key={importFile.id}>
                      <Table.Cell collapsing>
                        {(importFile.fileStatus === true) ? <Icon name='folder' size="large" color="green" />
                          : <Icon name='folder open' size="large" color="yellow" />}&nbsp;
                          <Link to={`/imports/${importFile.slug}`}>{importFile.fileNo}</Link>
                      </Table.Cell>
                      <Table.Cell>{importFile.mblNo}</Table.Cell>
                      <Table.Cell>{importFile.hblNo}</Table.Cell>
                      <Table.Cell>{importFile.importer}</Table.Cell>
                      <Table.Cell>{importFile.notifyParty}</Table.Cell>
                      <Table.Cell>{importFile.etd}</Table.Cell>
                      <Table.Cell>{importFile.eta}</Table.Cell>
                      <Table.Cell collapsing textAlign='right'>
                        {format((importFile.updatedOn) ? importFile.updatedOn.toDate() : importFile.createdOn.toDate())}
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            }}
          </FirestoreCollection>

          <Table.Footer>
            <Table.Row>
              <FirestoreDocument
                path="statistics/importCollections"
                render={({ isLoading, data }) => {
                  let menuItems = [];
                  let lastPage = (data === null) ? 0 : data.imports / this.state.pageSize;
                  let anyPageBool = false;
                  if (!isLoading) {
                    for (let i = 0; i <= lastPage; i++) {
                      if (i > 4 && i < lastPage - 4) {
                        if (!anyPageBool)
                          menuItems.push(
                            <Menu.Item key={i}>
                              <Input
                                label="Go to"
                                type="number"
                                placeholder={this.state.currentPage + 1}
                                icon={<Icon name='archive' inverted circular link />}
                                onChange={(_, data) => { this.gotoPage(data.value - 1) }}
                              />
                            </Menu.Item>
                          );
                        anyPageBool = true;
                      }
                      else
                        menuItems.push(
                          <Menu.Item key={i} as='a' icon onClick={() => this.gotoPage(i)}
                            disabled={(this.state.currentPage === i)}>
                            {i + 1}
                          </Menu.Item>
                        );
                    }
                  }

                  return (isLoading || (this.state.searchFilter)) ? (
                    null
                  ) : (
                      <Table.HeaderCell colSpan='9'>
                        <Menu floated='right' pagination>
                          <Menu.Item as='a' icon onClick={this.lastPage}
                            disabled={(this.state.currentPage === 0) ? true : false}>
                            <Icon name='chevron left' />
                          </Menu.Item>
                          {menuItems}
                          <Menu.Item as='a' icon onClick={this.nextPage}
                            disabled={(this.state.currentPage * this.state.pageSize + this.state.pageSize >= data.imports) ? true : false}>
                            <Icon name='chevron right' />
                          </Menu.Item>
                        </Menu>
                      </Table.HeaderCell>
                    );
                }}
              />
            </Table.Row>
          </Table.Footer>
        </Table>

        {(!this.state.searchFilter) && (
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan='7'>Templates</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Template Name</Table.HeaderCell>
                <Table.HeaderCell>Importer</Table.HeaderCell>
                <Table.HeaderCell>Shipper</Table.HeaderCell>
                <Table.HeaderCell>Final Destination</Table.HeaderCell>
              </Table.Row>
            </Table.Header>


            <FirestoreCollection
              path={'importTemplates'}
              sort="fileNo:desc"
              limit={this.state.templateCurrentPage * this.state.templatePageSize + this.state.templatePageSize}
            >
              {({ error, isLoading, data }) => {

                if (error) {
                  return <Error error={error} />
                }

                if (isLoading) {
                  return <Table.Body>
                    <Table.Row>
                      <Table.Cell>Loading, Please wait...</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                }

                if (data.length === 0) {
                  return <Table.Body>
                    <Table.Row>
                      <Table.Cell>No templates found</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                }

                return <Table.Body>
                  {data.slice(this.state.templateCurrentPage * this.state.templatePageSize,
                    this.state.templateCurrentPage * this.state.templatePageSize + this.state.templatePageSize).map(importTemplate => (
                      <Table.Row key={importTemplate.id}>
                        <Table.Cell collapsing>
                          <Icon name='folder' /> <Link to={`/imports/${importTemplate.slug}/template`}>{importTemplate.fileNo}</Link>
                        </Table.Cell>
                        <Table.Cell>{importTemplate.importer}</Table.Cell>
                        <Table.Cell>{importTemplate.shipperName}</Table.Cell>
                        <Table.Cell>{importTemplate.finalDestination}</Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>

              }}
            </FirestoreCollection>

            <Table.Footer>
              <Table.Row>
                <FirestoreDocument
                  path="statistics/importCollections"
                  render={({ isLoading, data }) => {
                    let menuItems = [];
                    let lastPage = (data === null) ? 0 : data.importTemplates / this.state.templatePageSize;
                    let anyPageBool = false;
                    if (!isLoading) {
                      for (let i = 0; i <= lastPage; i++) {
                        if (i > 4 && i < lastPage - 4) {
                          if (!anyPageBool)
                            menuItems.push(
                              <Menu.Item key={i}>
                                <Input
                                  label="Go to"
                                  type="number"
                                  placeholder={this.state.templateCurrentPage + 1}
                                  icon={<Icon name='archive' inverted circular link />}
                                  onChange={(_, data) => { this.gotoPageTemplate(data.value - 1) }}
                                />
                              </Menu.Item>
                            );
                          anyPageBool = true;
                        }
                        else
                          menuItems.push(
                            <Menu.Item key={i} as='a' icon onClick={() => this.gotoPageTemplate(i)}
                              disabled={(this.state.templateCurrentPage === i)}>
                              {i + 1}
                            </Menu.Item>
                          );
                      }
                    }
                    return isLoading ? (
                      <Table.HeaderCell colSpan='4' />
                    ) : (
                        <Table.HeaderCell colSpan='4'>
                          <Menu floated='right' pagination>
                            <Menu.Item as='a' icon onClick={this.lastPageTemplate}
                              disabled={(this.state.templateCurrentPage === 0) ? true : false}>
                              <Icon name='chevron left' />
                            </Menu.Item>
                            {menuItems}
                            <Menu.Item as='a' icon onClick={this.nextPageTemplate}
                              disabled={(this.state.templateCurrentPage * this.state.templatePageSize + this.state.templatePageSize >= data.importTemplates) ? true : false}>
                              <Icon name='chevron right' />
                            </Menu.Item>
                          </Menu>
                        </Table.HeaderCell>
                      );
                  }}
                />
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
      </React.Fragment>
    )
  }
}

export default ImportList
