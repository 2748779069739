import Firebase from 'firebase/compat/app'
import ReactGA from 'react-ga'
import slugify from 'slugify'

import { prepareDocForCreate } from './helpers/firestoreHelpers'

const createImport = values => {

  ReactGA.event({
    category: 'Import',
    action: 'Create Import',
  })

  values.ownedByName = Firebase.auth().currentUser.displayName
  values.lastUpdatedTimestamp = (new Date()).getTime();
  values.slug = slugify(values.fileNo, { lower: true })

  Firebase.firestore().collection('statistics').doc('importCollections').update({
    imports: Firebase.firestore.FieldValue.increment(1)
  });

  return Firebase.firestore()
    .collection('imports')
    .where('slug', '==', values.slug)
    .limit(1).get().then((doc) => {
      if (doc.size > 0) {
        alert(`Error! Could not create a new import: File ${values.fileNo} already exists`);
      } else {
        Firebase.firestore()
          .collection('imports')
          .add(prepareDocForCreate(values))
          .then(() => values)
          .catch(error => {
            alert(`Error! Could not create a new import: ${error.message}`)
          })
      }
      return values;
    }).catch(error => {
      alert(`Error! Could not create a new import: ${error.message}`);
    });
}

export default createImport
