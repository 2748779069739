import Firebase from 'firebase/compat/app'
import ReactGA from 'react-ga'

const deleteImportTemplate = templateFile => {

  ReactGA.event({
    category: 'Import Template',
    action: 'Delete Template',
  })

  Firebase.firestore().collection('statistics').doc('importCollections').update({
    importTemplates: Firebase.firestore.FieldValue.increment(-1)
  });

  return Firebase.firestore()
    .collection('importTemplates')
    .doc(templateFile.id)
    .delete()
    .catch(error => {
      alert(`Error! Could not delete the template: ${error.message}`)
    })
}

export default deleteImportTemplate
