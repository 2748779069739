import Firebase from 'firebase/compat/app'
import ReactGA from 'react-ga'

const deleteImport = importFile => {

  ReactGA.event({
    category: 'Import',
    action: 'Delete import',
  })

  Firebase.firestore().collection('statistics').doc('importCollections').update({
    imports: Firebase.firestore.FieldValue.increment(-1)
  });

  return Firebase.firestore()
    .collection('imports')
    .doc(importFile.id)
    .delete()
    .catch(error => {
      alert(`Error! Could not delete the import: ${error.message}`)
    })
}

export default deleteImport
